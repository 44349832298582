'use strict';

module.exports = function () {
    $('.imagetiles-carousel').slick({
        slidesToShow: 3,
        slidesToscroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
};

